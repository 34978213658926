import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Product Samples Are On The Way!`}</h1>
    <p>{`It’s Monday. Yep, still my day!`}</p>
    <p>{`Last week’s mission was to order samples for our ecom project, because samples
have a ~1-2 month lead time. Plant seeds. Wait for them to grow.`}</p>
    <p>{`I ordered these 5 product samples.`}</p>
    <p><strong parentName="p">{`1. Apple Cider Vinegar Gummies`}</strong>{` - supplements is a great category if you can
find a niche. High margin, repeat purchase, and easy to ship. Apple cider
vinegar is very healthy, but gross to drink. I think gummies have potential.`}</p>
    <p><strong parentName="p">{`2. Wrist Watches`}</strong>{` - I like taking popular ecom stores (eg. MVMT Watches) and
doing it for new niches (eg. middle america, rather than NY/LA/SF audience). My
brother-in-law Aaron is the man with this one.`}</p>
    <p><strong parentName="p">{`3. Pop Wall Art`}</strong>{` - what happens when you spend all day in your home office on
zoom calls? You want those walls behind you to look good. I get targeted like
crazy for `}<a parentName="p" {...{
        "href": "https://ikonick.com/collections/best-sellers"
      }}>{`these.`}</a>{`..which tells me
someone is making money doing this. I used nathan’s tactic and sourced these
straight off of alibaba`}</p>
    <p><strong parentName="p">{`4. Kids Play Couch`}</strong>{` - my daughter just turned 1, so I’m big into baby
products. I like the idea of a kids couch that is fun (eg. re-arrange cushions
into forts and tunnels) while also safe (no hard edges) and doesn’t make our
house look like legoland (neutral colors). Nathan from
`}<a parentName="p" {...{
        "href": "https://www.sourcify.com/"
      }}>{`Sourcify`}</a>{` is helping us find a supplier here.`}</p>
    <p><strong parentName="p">{`5. Bamboo Bed Sheets`}</strong>{` - hat tip to alex for this one, bed sheets have great
margins, and bamboo is a trendy fabric because it’s softer than cotton,
hypoallergenic, and eco friendly.`}</p>
    <p><strong parentName="p">{`And here’s my short list of product ideas I liked, but chose not to pursue
just because I didn’t have time to do them all (feel free to steal these if you
think they will work for you)`}</strong></p>
    <p><strong parentName="p">{`1. State themed blankets/towels`}</strong>{` - State names are free, recognizeable IP
that you can use. Targeting by state is quite effective. Can even do this at
city level. People have pride in their state, especially during election windows`}</p>
    <p><strong parentName="p">{`2. Inflatable beds for the back of cars -`}</strong>{` You can do hyper targeting and
brand it as the best accessory for the back of a Tesla or Ford F150. Roadtrip
travel is at an all time high. These are simple to get made, and high margin
(75-80%)These are simple to get made, and high margin (75-80%). Photography can
be aspirational and “scroll stopping” in feed (eg.
`}<a parentName="p" {...{
        "href": "https://dreamcase.eu/"
      }}>{`this tesla one)`}</a></p>
    <p><strong parentName="p">{`3. Sex toys/dildos (sold via onlyfans influencers)`}</strong>{` - Big market, underserved
by DTC/ecommerce players. Others are scared to do it, so their fear is your
opportunity. Onlyfans influencers are untapped. Easy to buy off the shelf from
Alibaba, minimal customization needed.`}</p>
    <p><strong parentName="p">{`If you missed any of last week’s posts or wanted a list of the resources we
used last week to validated demand and source the products,`}</strong>{`
`}<a parentName="p" {...{
        "href": "https://www.dropbox.com/s/36fv2o7koeu2m00/Tools%20%26%20Tips%20to%20Launching%20an%20Ecommerce%20Product.pdf?dl=0"
      }}>{`here’s a handy PDF you can download`}</a>{`.`}</p>
    <p>{`Can’t wait to see how all these samples turn out (we just got our first one
today)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAMFAQIE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMA/9oADAMBAAIQAxAAAAFm82jKlggjE3pU8UcYDv/EABsQAAICAwEAAAAAAAAAAAAAAAECAAMREiEy/9oACAEBAAEFAi+srsfaK+bQ3WtfPQy3WE9MPhfOZ//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwHURH//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Bata//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAExEBFh/9oACAEBAAY/AiPEQrOqkWMT8z//xAAcEAACAwEAAwAAAAAAAAAAAAAAAREhMUFRYXH/2gAIAQEAAT8hRuZGuKfJJ1DGMKDChQ82EEiE7GrNPo+4Gy6f/9oADAMBAAIAAwAAABBYHQ3/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAwEBPxBFCzrP/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPxAYViv/xAAcEAEAAwEBAAMAAAAAAAAAAAABABEhQTFhkeH/2gAIAQEAAT8QXGxeBB9ARa8zWjk1It/eSiXsevsLWZcbwWUlLbsZKU+Un7ALSnUMuEAelhmOja4kVxP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Apple Cider Vinegar Gummies",
          "title": "Apple Cider Vinegar Gummies",
          "src": "/static/70ac9d2501b0087e5d8a5b69f5fdeb54/04bec/acv-gummies-sample.jpg",
          "srcSet": ["/static/70ac9d2501b0087e5d8a5b69f5fdeb54/a6407/acv-gummies-sample.jpg 400w", "/static/70ac9d2501b0087e5d8a5b69f5fdeb54/a24e6/acv-gummies-sample.jpg 800w", "/static/70ac9d2501b0087e5d8a5b69f5fdeb54/04bec/acv-gummies-sample.jpg 1600w", "/static/70ac9d2501b0087e5d8a5b69f5fdeb54/fdb5a/acv-gummies-sample.jpg 2400w", "/static/70ac9d2501b0087e5d8a5b69f5fdeb54/b064b/acv-gummies-sample.jpg 3024w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <hr></hr>
    <p><strong parentName="p">{`OK..so what’s next!`}</strong></p>
    <p>{`We said from the start we’re going to launch three different `}{`$`}{`1M+ ventures.`}</p>
    <ul>
      <li parentName="ul">{`#1 - An investment fund (✅ `}{`$`}{`2.6M raised)`}</li>
      <li parentName="ul">{`#2 - An information product (that’s next!)`}</li>
      <li parentName="ul">{`#3 - An ecommerce product (✅ samples ordered, will circle back to this when
they arrive)`}</li>
    </ul>
    <p>{`This week we’re going to `}<strong parentName="p">{`finish up ＃1`}</strong>{` & then `}<strong parentName="p">{`kickoff venture #2.`}</strong></p>
    <p>{`-shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://www.dropbox.com/s/36fv2o7koeu2m00/Tools%20%26%20Tips%20to%20Launching%20an%20Ecommerce%20Product.pdf?dl=0',
        label: 'Tools & Tips to Launching an Ecommerce Product PDF'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://ikonick.com/collections/best-sellers',
        label: 'Motivational Wall Art'
      }, {
        url: 'https://www.sourcify.com/',
        label: 'Sourcify'
      }, {
        url: 'https://dreamcase.eu/',
        label: 'Tesla Inflatable Bed'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.tools_tips_tricks_to_finding_a_manufacturer.label,
      slug: slugify(postMap.tools_tips_tricks_to_finding_a_manufacturer.slug)
    }} nextButton={{
      text: postMap.growth_is_math_art_science.label,
      slug: slugify(postMap.growth_is_math_art_science.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      